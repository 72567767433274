//Used in vuetify's v-data-table's headers
export default class DataTableHeader {
  constructor(init?: Partial<DataTableHeader>) {
    Object.assign(this, init);
  }

  text!: string;
  value!: string;
  align?: "start" | "center" | "end";
  sortable?: boolean;
  filterable?: boolean;
  divider?: boolean;
  class?: string | string[];
  width?: string | number;
  filter?: (value: any, search: string, item: any) => boolean;
  sort?: (a: any, b: any) => number;
}
