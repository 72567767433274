
























































import { Component, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import lodash from "lodash";
import * as Models from "@gigalot/data-models";
import { factory } from "@gigalot/data-models";

@Component({
  components: {
    DimssaButton
  }
})
export default class RationPlans extends Vue {
  loading = false;
  time = "";
  tableKey = 0;
  rationPlans: Models.RationPlanItem[] = [];
  selected : any[] = [];
  headersRationPlan = [
    {
      text: "Name",
      value: "name",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Created On",
      value: "creationDate",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Rations",
      value: "rations",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Duration",
      value: "days",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    }
  ];

  mounted() {
    this.$store.dispatch("user/addFirebaseCallback", this.getData);
  }

  get moment() {
    return this.$store.state.moment;
  }
    forceRerender() {
    this.tableKey += 1;
  }

  getDays(item: any) {
    let totaldays = 0;
    for (let i = 0; i < item.rationItems.length; i++) {
      totaldays = totaldays + item.rationItems[i].defaultDays;
    }
    return totaldays;
  }

  async makeDefault(object: any) { 
     console.log(object);
    if (object) {
      try {
        let json = await this.$store.dispatch(
          "graphQl",
          {
            gql: `mutation defaultRationPlan($guid:String!, $input: RationPlanInput!) {
            defaultRationPlan(guid:$guid, input: $input) 
            }`,
            variables: {
              guid: this.$store.state.user.location.guid,
              input: object
            }
          },
          { root: true }
        );
       
       } catch (err) {
        console.log(err);
        throw err;
      }
    }
  }
  
  addRationPlan() {
    this.onClickEdit(this.selected[0]);
    this.$router.push("rationPlan");
  }

  removeRationPlan(){
    if (this.selected[0]){
    lodash.remove(this.rationPlans,{guid: this.selected[0].guid});
    this.forceRerender();
    }
  }

  onClickEdit(selected: Models.RationPlan) {
    let item = this.selected[0] ? this.selected[0] : factory("RationPlan");
   
    this.$store.commit("updateField", {
      path: "rationPlan",
      value: item
    });
    this.$router.push("rationPlan");
  }

  async getData() {
    this.loading = true;
    try {
      console.log("getRationPlans()");

      let gql = `query($guid:String!){
         RationPlans(guid:$guid) {
          typename
          guid
          creationDate
          name
          rationItems{
            typename
            guid
            ration
            rationGuid
            defaultDays
            colour
            order
            variable
            priority
          }
        }
      }`;
      let json = await this.$store.dispatch("graphQl", { gql, variables:{guid:this.$store.state.user.location.guid }, url: "https://pi.gigalot.systems:7777/feeder" });

      console.log("Done Downloading");
      this.rationPlans = json.data.RationPlans;
    } catch (err) {
      console.log("getRationPlans -> error: " + err);
    } finally {
      this.loading = false;
    }
  }

  save(item: any) {
    console.log("Save");
    this.updateServer(item);
  }

  cancel() {
    console.log("Cancel");
  }
  close() {
    console.log("Close");
  }

  async updateServer(object?: string) {
    console.log(object);
    if (object) {
      try {
        let json = await this.$store.dispatch(
          "graphQl",
          {
            gql: `mutation addRationPlan($guid:String!, $input: RationPlanItemInput!) {
            addRationPlan(guid:$guid, input: $input) 
            }`,
            variables: {
              guid: this.$store.state.user.location.guid,
              input: object
            }
          },
          { root: true }
        );
       
       } catch (err) {
        console.log(err);
        throw err;
      }
    }
  }
}
