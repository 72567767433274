import { default as Vuex, Module, ActionContext } from "vuex";
import * as DataModels from "@gigalot/data-models";
import Uploadable, { UploadableScanningResult } from "@/models/uploadable";

class CribScoreState {
}

export default new (class CribScore implements Module<CribScoreState, any> {
  namespaced = true;
  state: CribScoreState = new CribScoreState();
  mutations = {
    batchNumber(state: CribScoreState, batchNumber: string | undefined) {},
    batchQuantity(state: CribScoreState, batchQuantity: number | undefined) {}
  };
  actions = {
    async save(context: ActionContext<CribScoreState, any>, cribScoreItem: DataModels.CribReadingItem) {
      let metadata: DataModels.UpstreamMetadata = context.rootGetters["upload/getUpstreamMetadata"];
      let cribScore: Uploadable = Object.assign(cribScoreItem, new Uploadable());
      await context.dispatch("data/addCribScore", cribScore, { root: true });
      try {
        if (!context.rootState.upload.isBusyUploading) {
          context.dispatch("upload/upload", undefined, { root: true });
        }
      } catch (e) {
        //silent failure is fine here, upload will likely fail here anyway if the user is still on the scanner's wifi
      }
    }
  };
  getters = {
    /*
    getter(state: State, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
  };
})();
