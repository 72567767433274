import { default as Vuex, Module, ActionContext } from "vuex";
import Header from "@/data-table-header";
import lodash from "lodash";
import { ObjectStoreNames } from "@/data-manager";
import router from "@/router";

class ListState {
  title: string = "";
  headers?: Header[] = [];
  items?: any[] = [];
  typename!: string;
  objectStoreName?: ObjectStoreNames;
  onEdit: { routeName?: string; listUponEditPath?: string } = {};
  onContinue?: { routeName?: string; listUponSelectionSetPath: string };
}

export default new (class List implements Module<ListState, any> {
  namespaced = true;
  state: ListState = new ListState();
  mutations = {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }*/
    list(
      state: ListState,
      payload: {
        title: string;
        headers?: Header[];
        items?: any[];
        typename: string;
        onEdit: {
          routeName: string; //name of route where selected item can be edited.
          listUponEditPath: string; //the path to set in storage[uid][guid] upon edit
        };
        onContinue?: {
          routeName: string; //name of route where selected item can be selected.
          listUponSelectionSetPath: string; //the storage path to set to the selected item
        };
      }
    ) {
      state.title = payload.title;
      state.headers = payload.headers;
      state.items = payload.items;
      state.typename = payload.typename;
      state.onEdit = payload.onEdit;
      state.onContinue = payload.onContinue;

      if (state.headers === undefined) {
        //lookup headers for typename
      }
      if (state.items === undefined) {
        //lookup items for typename
      }
    },
    onContinue(
      state: ListState,
      onContinue?: {
        routeName: string; //name of route where selected item can be selected.
        listUponSelectionSetPath: string; //the storage path to set to the selected item
      }
    ) {
      state.onContinue = onContinue;
    }
  };
  actions = {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
  };
  getters = {
    /*
    getter(state: State, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
  };
})();
