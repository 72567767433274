
















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import { mapMutations } from "vuex";
import * as Models from "@gigalot/data-models";
import lodash from "lodash";
const vuexMapFields = require("vuex-map-fields");
const mapFields = vuexMapFields.mapFields;
import {required, minLength, alphaNum, between } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate'
import store from "@/store";

function duplicated(value:string){
  const cm = store.getters["storage"]().kraal
  const kraals = store.getters["storage"]().Kraals.filter((m: any) => m.guid !== cm.guid);
  const f = lodash.find(kraals,{kraalId:value});
  return f ? false : true;
} 

@Component({
  components: {
    DimssaButton
  },
  mixins: [validationMixin],
  validations : {kraalId: {required, duplicated}, recommendedCapacity:{required, between:between(0,1000)}},
  computed: {
    ...mapFields([
      "kraal.kraalId",
      "kraal.description",
      "kraal.lefthandSGLN",
      "kraal.righthandSGLN",
      "kraal.recommendedCapacity",
      "kraal.kraalType",
      "kraal.active",
      "kraal.gps",
      "kraal.sgln",
      "kraal.leftCoordinate",
      "kraal.rightCoordinate"
    ])
  }
})
export default class Kraal extends Vue {
  kraalTypes: Models.KraalType[] = [];
  loading = false;
  changed = false;
  get moment() {
    return this.$store.state.moment;
  }

  async getData() {
    this.loading = true;
    try {
      console.log("getData()");

      let gql = `query($guid:String!){
        KraalType(guid:$guid) {
          typename
          guid
          kraalType
          doFeed
          feedType
          noOfSessions
        }

      }`;
      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: { guid: this.$store.state.user.location.guid },
        url: "https://pi.gigalot.systems:7777/feeder"
      });

      console.log("Done Downloading");
      this.kraalTypes = json.data.KraalType;
    
    } catch (err) {
      console.log("getData -> error: " + err);
    } finally {
      this.loading = false;
    }
  }

  cancel() {
    if (this.changed){
      this.$store.commit("popup/displayYesNo", {
      message: "Discard changes?",
      yesAction: () => {
        this.$store.commit("updateField", {
          path: "kraal",
          value: {}
        });
        this.$router.go(-1);
      }
    });
    }
    else {
      this.$router.go(-1);
    }
    
  }

  save() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure you want to save?",
      yesAction: async () => {
        try {
          console.log(this.$store.getters["storage"]().kraal);
          let kraalClone = lodash.cloneDeep(this.$store.getters["storage"]().kraal);
          kraalClone.metadata = this.$store.getters["user/getUpstreamMetadata"](); //new Models.UpstreamMetadata(userName, userId, userEmail, feedlot, gcp, location);
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation kraal($guid: String!, $kraal: KraalInput!) {
                kraal(guid: $guid, kraal: $kraal)
              }`,
              variables: {
                guid: this.$store.state.user.location.guid,
                kraal: kraalClone
              }
            },
            { root: true }
          );
         
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/displayOk", {
            message: "Error occured while trying to save to server"
          });
          throw err;
        }
        await this.kraals();
      }
    });
  }

  async kraals(push: boolean = true) {
    try {
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.kraals,
          variables: { guid: this.$store.state.user.location.guid }
        },
        { root: true }
      );
      
      this.$store.commit("updateField", {
        path: "Kraals",
        value: json.data.Kraals
      });
    } catch (err) {
      console.log(err);
       this.$store.commit("popup/displayOk", {
            message: `Error: ${err}`
          });
    }
    if (push) this.$router.push({ path: "list/kraals" });
  }

  mounted() {
      this.$store.dispatch("user/addFirebaseCallback", this.getData);
  }

  destroyed() {}
}
