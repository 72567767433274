import { default as Vuex, Module, ActionContext } from "vuex";
import * as DataModels from "@gigalot/data-models";
import { uploadCribScore } from "@/helpers/upload-helpers";
import { UploadableModel } from "@/models/uploadable";
export class UploadState {
  isBusyUploading: boolean = false;
}

export default new (class Upload implements Module<UploadState, any> {
  namespaced = true;
  state: UploadState = new UploadState();
  mutations = {
    setIsBusyUploading(state: UploadState, payload: boolean) {
      state.isBusyUploading = payload;
    },
  };
  actions = {
    async upload(context: ActionContext<UploadState, any>) {
      context.commit("setIsBusyUploading", true);
      try {
        await context.dispatch("uploadCribScores");
      } catch (err) {
        console.log("upload error: " + err);
      } finally {
        context.commit("setIsBusyUploading", false);
      }
    },
    async uploadCribScores(context: ActionContext<UploadState, any>) {
      console.log("uploadCribScores");
      let cribScores: UploadableModel[];
      cribScores = await context.dispatch("data/getCribScores", null, { root: true });
      let toUpload = cribScores.filter(cribScores => !cribScores.uploaded);
      for (let i = 0; i < toUpload.length; ++i) {
        await uploadCribScore(toUpload[i], context);
      }
    },
   };
  getters = {
    getUpstreamMetadata(state: UploadState, getters: any, rootState: any, rootGetters: any) {
      let userName: string = rootState.user.user.displayName;
      let userId: string = rootState.user.user.uid;
      let userEmail: string = rootState.user.user.email;
      let feedlot: string = ""//rootGetters["feedlots/feedlotName"](rootState.user.gcp); //TODO: Fix feedlot name in upstreme metadata
      let gcp: string = rootState.user.gcp;
      let location: string = rootState.user.location;
      let metadata: DataModels.UpstreamMetadata = new DataModels.UpstreamMetadata(userName, userId, userEmail, feedlot, gcp, location);
      return metadata;
    }
  };
})();
