
































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import { mapMutations } from "vuex";
import * as Models from "@gigalot/data-models";
import lodash from "lodash";

const vuexMapFields = require("vuex-map-fields");
const mapFields = vuexMapFields.mapFields;
import {required, minLength, alphaNum, between } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate'
import store from "@/store";

function duplicated(value:string){
  const cm = store.getters["storage"]().trailer
  const trailers = store.getters["storage"]().Trailers.filter((m: any) => m.guid !== cm.guid);
  const f = lodash.find(trailers,{electronicID:value});
  return f ? false : true;
} 
@Component({
  components: {
    DimssaButton
  },
  mixins: [validationMixin],
  validations : {electronicID :{required, duplicated}, wagonRegistration:{required},minCapacity:{required,between:between(0,20000)},maxCapacity:{required,between:between(0,20000)}},
  computed: {
    ...mapFields([
      "trailer.maxCapacity",
      "trailer.minCapacity",
      "trailer.wagonType",
      "trailer.electronicID",
      "trailer.wagonRegistration",
      "trailer.deliverySide",
      "trailer.active"
    ])
  }
})
export default class Trailer extends Vue {
    cancel() {
    if (this.changed){
      this.$store.commit("popup/displayYesNo", {
      message: "Discard changes?",
      yesAction: () => {
        this.$store.commit("updateField", {
          path: "trailer",
          value: {}
        });
        this.$router.go(-1);
      }
    });
    }
    else {
      this.$router.go(-1);
    }
   
  }
  changed: boolean = false;
  save() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure you want to save?",
      yesAction: async () => {
        try {
          console.log(this.$store.getters["storage"]().trailer);
          let trailerClone = lodash.cloneDeep(this.$store.getters["storage"]().trailer);
          trailerClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();//new Models.UpstreamMetadata(userName, userId, userEmail, feedlot, gcp, location);
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation trailer($guid: String!, $trailer: TrailerInput!) {
                trailer(guid: $guid, trailer: $trailer)
              }`,
              variables: {
                guid: this.$store.state.user.location.guid,
                trailer: trailerClone
              }
            },
            { root: true }
          );

        } catch (err) {
          console.log(err);
          this.$store.commit("popup/displayOk", {
            message: "Error occured while trying to save to server"
          });
          throw err;
        }
        await this.trailers();
      }
    });
  }

 async trailers(push: boolean = true) {
    try {
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.trailers,
          variables: { guid: this.$store.state.user.location.guid }
        },
        { root: true }
      );
     
      this.$store.commit("updateField", {
        path: "Trailers",
        value: json.data.Trailers
      });
    } catch (err) {
      console.log(err);
      this.$store.commit("popup/displayOk", {
            message: `Error: ${err}`
          });
    }
    if (push) this.$router.push({ path: "list/trailers" });
  }
  
  mounted() {}

  destroyed() {}
}
