









































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import { mapMutations } from "vuex";
import * as Models from "@gigalot/data-models";
import lodash from "lodash";
import {required, minLength, alphaNum } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate'

const vuexMapFields = require("vuex-map-fields");
const mapFields = vuexMapFields.mapFields;
import store from "@/store";

function duplicated(value:string){
  const cm = store.getters["storage"]().tractor
  const tractors = store.getters["storage"]().Tractors.filter((m: any) => m.guid !== cm.guid);
  const f = lodash.find(tractors,{electronicID:value});
  return f ? false : true;
} 

@Component({
  components: {
    DimssaButton
  },
  mixins: [validationMixin],
  validations : {electronicID:{required,duplicated},registrationNumber:{required}},
  computed: {
    ...mapFields([
      "tractor.registrationNumber",
      "tractor.isOperating",
      "tractor.defaultRation",
      "tractor.electronicID",
      "tractor.emergencyRation",
      "tractor.active"
    ])
  }
})
export default class Tractor extends Vue {
  changed: boolean = false;
  get Rations() {
    return this.$store.getters["storage"]().Rations as Models.Ration[];
  }
  cancel() {
    if (this.changed){
      this.$store.commit("popup/displayYesNo", {
      message: "Discard changes?",
      yesAction: () => {
        this.$store.commit("updateField", {
          path: "tractor",
          value: {}
        });
        this.$router.go(-1);
      }
    });
    }
    else {
      this.$router.go(-1);
    }
   
  }

  save() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure you want to save?",
      yesAction: async () => {
        try {
          let tractor = this.$store.getters["storage"]().tractor;
          if (tractor.emergencyRation){
          if (tractor.emergencyRation.typename) {
            tractor.emergencyRation = tractor.emergencyRation.name;
          }
         
          } else {tractor.emergencyRation = "";}
          if (tractor.defaultRation){
          if (tractor.defaultRation.typename) {
            tractor.defaultRation = tractor.defaultRation.name;
          }
           
          }else {tractor.defaultRation = "";}
          let newTractor = new Models.Tractor();
          let tractorClone = lodash.cloneDeep(this.$store.getters["storage"]().tractor);
          lodash.assign(newTractor, tractorClone);
          newTractor.metadata = this.$store.getters["user/getUpstreamMetadata"](); //new Models.UpstreamMetadata(userName, userId, userEmail, feedlot, gcp, location);
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation tractor($guid: String!, $tractor: TractorInput!) {
                tractor(guid: $guid, tractor: $tractor)
              }`,
              variables: {
                guid: this.$store.state.user.location.guid,
                tractor: newTractor
              }
            },
            { root: true }
          );
         
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/displayOk", {
            message: "Error occured while trying to save to server"
          });
          throw err;
        }
       
        await this.tractors();
      }
    });
  }

async tractors(push: boolean = true) {
    try {
     
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.tractors,
          variables: { guid: this.$store.state.user.location.guid }
        },
        { root: true }
      );
      
      this.$store.commit("updateField", {
        path: "Tractors",
        value: json.data.Tractors
      });
    } catch (err) {
      console.log(err);
      this.$store.commit("popup/displayOk", {
            message: `Error: ${err}`
          });
    }
    if (push) this.$router.push({ path: "list/tractors" });
  }

  mounted() {}

  destroyed() {}
}
