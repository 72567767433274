var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-1",attrs:{"fluid":""}},[_c('v-divider'),_c('v-row',[_c('v-col',[_c('dimssa-button',{staticClass:"button",attrs:{"buttonState":_vm.refreshButtonState},on:{"onclick":function($event){return _vm.refresh()}}},[_vm._v("Refresh")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"dense":"","headers":[
          { text: 'Date', value: 'readingDate' },
          { text: 'Kraal', value: 'kraalId' },
          { text: 'Ration', value: 'ration' },
          { text: 'Score', value: 'score' },
          { text: 'Call', value: 'call' },
          { text: 'Status', value: 'status' }
        ],"items":_vm.cribScores},scopedSlots:_vm._u([{key:"item.readingDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.readingDate).format("YYYY-MM-DD HH:mm"))+" ")]}}],null,true)})],1)],1),_c('v-row')],1)}
var staticRenderFns = []

export { render, staticRenderFns }