/* eslint-disable no-console */
import { register } from "register-service-worker";
import store from "@/store/index";
import { Event, EventDispatcher } from "./event";

import { Workbox } from "workbox-window";
let swr: ServiceWorkerRegistration | undefined;
let wb: Workbox | null = null;

let _onRegistrationDispatcher: EventDispatcher<ServiceWorkerRegistration> = new EventDispatcher<ServiceWorkerRegistration>();

export async function registerServiceWorker() {
  if (wb) console.log("wb already created.");
  if (!wb) {
    console.log("registering workbox window");
    if ("serviceWorker" in navigator) {
      wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

      wb.addEventListener("controlling", () => {
        window.location.reload();
      });

      swr = await wb.register();
      if (swr) _onRegistrationDispatcher.dispatch(swr);
      _onRegistrationDispatcher as Event<ServiceWorkerRegistration>;
      console.log("registering workbox window (registered)");
    } else {
      wb = null;
      console.log("set workbox to null");
    }
  }
}

export {wb, swr, _onRegistrationDispatcher };
