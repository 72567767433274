import { createDataChannel, send, receive } from "@gigalot/rtc-client";
import * as graphQlWs from "graphql-ws";

abstract class UsedWebSocketClient {
  static readonly CLOSED: number = 0;
  static readonly CLOSING: number = 1;
  static readonly CONNECTING: number = 2;
  static readonly OPEN: number = 3;
  abstract readyState: number;
  abstract send(data: string | ArrayBufferLike | Blob | ArrayBufferView): void;
  abstract onclose: ((this: UsedWebSocketClient, ev: CloseEvent) => any) | null;
  abstract onerror: ((this: UsedWebSocketClient, ev: Event) => any) | null;
  abstract onmessage: ((this: UsedWebSocketClient, ev: MessageEvent) => any) | null;
  abstract onopen: ((this: UsedWebSocketClient, ev: Event) => any) | null;
  abstract close(code?: number, reason?: string): void;
}

function readyState(dataChannel: RTCDataChannel | null) {
  if (!dataChannel) return undefined;
  switch (dataChannel.readyState) {
    case "closed":
      return UsedWebSocketClient.CLOSED;
    case "closing":
      return UsedWebSocketClient.CLOSING;
    case "connecting":
      return UsedWebSocketClient.CONNECTING;
    case "open":
      return UsedWebSocketClient.OPEN;
  }
}

export function createClient() {
  const dataChannel = createDataChannel();
  if (!dataChannel) return;
  class RtcClientWebSocketAdapter extends UsedWebSocketClient {
    constructor() {
      super();
      this.readyState = RtcClientWebSocketAdapter.CLOSED;

      dataChannel.onclose = (ev: Event) => {
        console.log("test-rtc-client onclose: " + JSON.stringify(ev));
        this.readyState = readyState(dataChannel) ?? UsedWebSocketClient.CLOSED;
        this.onclose?.(new CloseEvent("TODO", { code: 0, reason: "TODO", wasClean: true }));
      };
      dataChannel.onerror = (ev: Event) => {
        console.log("test-rtc-client onerror: ", ev);
        let readyStateVal = readyState(dataChannel);
        if (readyStateVal) this.readyState = readyStateVal;
        this.onerror?.(ev);
      };
      dataChannel.onmessage = async (ev: MessageEvent) => {

        //this.onmessage?.(ev);
        const self = this;
        await receive(ev, dataChannel, (ev: any) => {
          console.log("test-rtc-client message received: " + ev.data);
          if (self.onmessage) self.onmessage(ev);
        });
      };
      dataChannel.onopen = (ev: Event) => {
        console.log("test-rtc-client onopen: " + JSON.stringify(ev));
        this.readyState = readyState(dataChannel) ?? UsedWebSocketClient.OPEN;
        this.onopen?.(ev);
      };
    }
    readyState: number;
    send(data: string | ArrayBufferLike | Blob | ArrayBufferView): void {
      console.log("RtcClientWebSocketAdapter.send", data);
      send(data, dataChannel);
    }
    onclose: ((this: UsedWebSocketClient, ev: CloseEvent) => any) | null = null;
    onerror: ((this: UsedWebSocketClient, ev: Event) => any) | null = null;
    onmessage: ((this: UsedWebSocketClient, ev: MessageEvent) => any) | null = null;
    onopen: ((this: UsedWebSocketClient, ev: Event) => any) | null = null;
    close(code?: number, reason?: string): void {
      console.warn(`Closing data channel. Code: ${code}, reason: ${reason}`);
      //RtcClient.close();
      dataChannel.close();
    }
  }

  const client = graphQlWs.createClient({
    url: "It don't matter",
    webSocketImpl: RtcClientWebSocketAdapter,
  });

  return client;
}