import Vue from "vue";
import VueRouter, { Route, NavigationGuard } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Location from "../views/Location.vue";
import FeedDelivery from "../views/FeedDelivery.vue";
import Options from "../views/Options.vue";
import store from "../store";
import CribPlan from "../views/CribPlan.vue";
import Cribs from "../views/Cribs.vue";
import FeedPlan from "../views/FeedPlan.vue";
import FeedSchedule from "../views/FeedSchedule.vue";
import Setup from "../views/Setup.vue";

import List from "@/views/Input/List.vue";
import Input from "@/views/Input/Input.vue";
import gMap from "@/views/Map.vue";
import Complex from "../views/Input/Complex.vue";
import Line from "../views/Input/Lines.vue";
import Kraal from "../views/Input/Kraal.vue";
import Tractor from "../views/Input/Tractor.vue";
import Trailer from "../views/Input/Trailer.vue";
import Operator from "../views/Input/Operator.vue";
import Loader from "../views/Input/Loader.vue";
import Ration from "../views/Input/Ration.vue";
import Material from "../views/Input/Material.vue";
import FeedDash from "@/views/FeedDash.vue";
import MixDash from "@/views/MillDash.vue";
import Reports from "@/views/Reports.vue";
import RationPlan from "@/views/Options/RationPlan.vue";
import RationPlans from "@/views/Options/RationPlans.vue";
import Notes from "@/views/Notifications.vue";
import Outbox from "@/views/Outbox.vue";
import FeedMixer from "@/views/FeedMixer.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    alias: ["/index.html", "/index"],
    component: Home,
    meta: { requiresRegistration: true }
  },
  {
    path: "/crib",
    name: "crib",
    component: CribPlan,
    meta: { requiresRegistration: true }
  },
  {
    path: "/cribscores",
    name: "cribscores",
    component: Cribs,
    meta: { requiresRegistration: true },
    props: (route: Route) => {
      let selectedKraal = route.query.selectedKraal === undefined ? undefined : decodeURI(route.query.selectedKraal as string);
      return {
        selectedKraal: selectedKraal
      };
    }
  },
  {
    path: "/feedplan",
    name: "plan",
    component: FeedPlan,
    meta: { requiresRegistration: true }
  },
  {
    path: "/feedmix",
    name: "mix",
    component: FeedMixer,
    meta: { requiresRegistration: true }
  },
  {
    path: "/feedschedule",
    name: "schedule",
    component: FeedSchedule,
    meta: { requiresRegistration: true }
  },
  {
    path: "/feeddelivery",
    name: "delivery",
    component: FeedDelivery,
    meta: { requiresRegistration: true }
  },
  {
    path: "/options",
    name: "options",
    component: Options,
    meta: { requiresRegistration: true }
  },
  {
    path: "/setup",
    name: "setup",
    component: Setup,
    meta: { requiresRegistration: true }
  },
  {
    path: "/list/:urlParam",
    name: "list",
    component: List,
    meta: { requiresRegistration: true },
    props: (route: Route) => {
      //if (!route.params.urlParam) throw Error("no urlParam for /list");
      let urlParam = route.params.urlParam === undefined ? undefined : decodeURI(route.params.urlParam as string);
      let setPath = route.query.setPath === undefined ? undefined : decodeURI(route.query.setPath as string);
      let continueRoute = route.query.continueRoute === undefined ? undefined : decodeURI(route.query.continueRoute as string);
      return {
        urlParam: urlParam,
        setPath: setPath,
        continueRoute: continueRoute
      };
    }
  },
  {
    path: "/outbox",
    name: "outbox",
    component: Outbox,
    meta: { requiresRegistration: true }
  },
  {
    path: "/kraal",
    name: "kraal",
    component: Kraal,
    meta: { requiresRegistration: true }
  },
  {
    path: "/complex",
    name: "complex",
    component: Complex,
    meta: { requiresRegistration: true }
  },
  {
    path: "/line",
    name: "line",
    component: Line,
    meta: { requiresRegistration: true }
  },
  {
    path: "/tractor",
    name: "tractor",
    component: Tractor,
    meta: { requiresRegistration: true }
  },
  {
    path: "/notifications",
    name: "notifications",
    component: Notes,
    meta: { requiresRegistration: true }
  },
  {
    path: "/trailer",
    name: "trailer",
    component: Trailer,
    meta: { requiresRegistration: true }
  },
  {
    path: "/operator",
    name: "operator",
    component: Operator,
    meta: { requiresRegistration: true }
  },
  {
    path: "/loader",
    name: "loader",
    component: Loader,
    meta: { requiresRegistration: true }
  },
  {
    path: "/ration",
    name: "ration",
    component: Ration,
    meta: { requiresRegistration: true }
  },
  {
    path: "/material",
    name: "material",
    component: Material,
    meta: { requiresRegistration: true }
  },
  {
    path: "/map",
    name: "map",
    component: gMap,
    meta: { requiresRegistration: true }
  },
  {
    path: "/reports",
    name: "reports",
    component: FeedDash,
    meta: { requiresRegistration: true }
  },
  {
    path: "/dashFeedReport",
    name: "dashFeedReport",
    component: FeedDash,
    meta: { requiresRegistration: true }
  },
  {
    path: "/dashMixReport",
    name: "dashMixReport",
    component: MixDash,
    meta: { requiresRegistration: true }
  },
  {
    path: "/rationPlans",
    name: "rationPlans",
    component: RationPlans,
    meta: { requiresRegistration: true }
  },
  {
    path: "/rationPlan",
    name: "rationPlan",
    component: RationPlan,
    meta: { requiresRegistration: true }
  },
  {
    path: "/location",
    name: "location",
    component: Location
    // beforeEnter: (to: Route, from: Route, next: any) => {
    //   if (store.getters["user/isUserRegistered"]()) next();
    //   else next("/login");
    // }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: (to: Route, from: Route, next: any) => {
      //If already logged in then go to the home screen
      console.log(store);
      if (store.getters["user/isUserRegistered"]()) next("/");
      //If not logged in then continue to login page
      else next();
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to: Route, from: Route, next: any) => {
  let appBarText = "";
  switch (to.name) {
    case "home":
      appBarText = "Main Menu";
      break;
    case "crib":
      appBarText = "Crib Scores";
      break;
    case "plan":
      appBarText = "Feed Planning";
      break;
    case "schedule":
      appBarText = "Feeding Schedule";
      break;
    case "delivery":
      appBarText = "Feed Delivery";
      break;
    case "map":
      appBarText = "Map";
      break;
    case "setup":
      appBarText = "Farm Setup";
      break;
    case "options":
      appBarText = "Options";
      break;
    case "notifications":
      appBarText = "Notifications";
    case "mix":
      appBarText = "Mix Schedule"
  }

  //Send user to login page if user is not registered (logged in)
  if (to.name === "login") {
    next();
    return;
  }

  store.commit("updateField", { path: "appBarText", value: appBarText });
  let requiresRegistration: boolean = to.matched.some(record => record.meta.requiresRegistration);
  let isUserRegistered: boolean = store.getters["user/isUserRegistered"]();

  if (requiresRegistration && !isUserRegistered) {
    next("login");
  } else if (to.name !== "location" && to.name !== "login" && !(store as any).state.user.location) {
    next("location");
  } else {
    next();
  }
});

export default router;
