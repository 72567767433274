



































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import { mapMutations } from "vuex";
import * as Models from "@gigalot/data-models";
import lodash from "lodash";
import {required, minLength, alphaNum } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate'
const vuexMapFields = require("vuex-map-fields");
const mapFields = vuexMapFields.mapFields;
import store from "@/store";

function duplicated(value:string){
  const cm = store.getters["storage"]().complex
  const complexes = store.getters["storage"]().Complexes.filter((m: any) => m.guid !== cm.guid);
  const f = lodash.find(complexes,{code:value});
  return f ? false : true;
} 
@Component({
  components: {
    DimssaButton
  }, 
  mixins: [validationMixin],
  validations : {code: {required, duplicated},
    displayName: {required},
    },
  computed: {
    ...mapFields(["complex.code", "complex.displayName", "complex.description", "complex.active", "complex.lines"])
  }
})
export default class Complex extends Vue {
  cancel() {
   
    this.$router.go(-1);
  }
  created() {
    let wtf = this.complexs
    let x = this.complexs.lines;
  }
  get complexs(): Models.Complex {
    return this.$store.getters["storage"]().complex
  }
  save() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure you want to save?",
      yesAction: async () => {
        try {
          console.log(this.$store.getters["storage"]().complex);
          let complexClone = lodash.cloneDeep(this.$store.getters["storage"]().complex);
          complexClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();// new Models.UpstreamMetadata(userName, userId, userEmail, feedlot, gcp, location);
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation complex($guid: String!, $complex: ComplexInput!) {
                complex(guid: $guid, complex: $complex)
              }`,
              variables: {
                guid: this.$store.state.user.location.guid,
                complex: complexClone
              }
            },
            { root: true }
          );
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/displayOk", {
            message: "Error occured while trying to save to server"
          });
          throw err;
        }
        this.complexes();
      }
    });
  }

  async complexes(push: boolean = true) {
    try {
     
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.complexes,
          variables: { guid: this.$store.state.user.location.guid }
        },
        { root: true }
      );
     
      this.$store.commit("updateField", {
        path: "Complexes",
        value: json.data.Complexes
      });
    } catch (err) {
      console.log(err);
      this.$store.commit("popup/displayOk", {
            message: `Error: ${err}`
          });
    }
    if (push) this.$router.push({ path: "list/complexes" });
  }

  mounted() {}

  destroyed() {}

  editComponentIndex: number | null = null;

  editComponent(index: number) {
    this.editComponentIndex = index;
  }

  confirmEditComponent(line: Models.Line, index: number) {
    line.complexGuid = this.complexs.guid;
    this.complexs.lines[index] = line;
    this.$store.commit("store", { complex: this.complexs });
    this.editComponentIndex = null;
  }
  
  addComponent() {
    let line: Models.Line = new Models.Line();
    
    this.complexs.lines.push(line);
    this.editComponentIndex = this.complexs.lines.length - 1;
  }

  
}
