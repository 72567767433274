






import { Component, Prop, Vue } from "vue-property-decorator";
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
type VersionState = "legacy" | "current" | "experimental";

@Component
export default class experimentalVersion extends Vue {
  @Prop() versionState!: VersionState;

  @Prop() buttonClass!: string;

  experimentalEnabled: boolean = false;
  name: String = "experimentalVersion";
  checkVersion(){
      if (this.experimentalEnabled){ //I think the lower numbers have priority for the first traffic splitting versions
           Vue.$cookies.set('GOOGAPPUID',0);
      }
      else                          // and the higher numbers have the later traffic split versions
          Vue.$cookies.set('GOOGAPPUID',999);
  }
}
export { experimentalVersion };
