import { openDB, DBSchema, IDBPDatabase, StoreNames } from "idb/with-async-ittr";
import { UploadableModel } from "./models/uploadable";

import { Kraal, /*MorbidityDescription,*/ Treatment, Vaccination, Dose } from "@gigalot/data-models";

import store from "@/store/index";

interface MyDB extends DBSchema {
  "saved-crib-scores": {
    key: string;
    value: UploadableModel;
    indexes: { locationGuid: string };
  };
  "todays-crib-scores": {
    key: string;
    value: any;
    indexes: { locationGuid: string };
  };
  "crib-scores-history": {
    key: string;
    value: any;
    indexes: { locationGuid: string };
  };
  "your-notifications": {
    key: string;
    value: any;
    indexes: {locationGuid: string };
  },
  "ration-dmfs":{
    key: string,
    value: any,
    indexes: {locationGuid: string };
  },
  "crib-reading-adjustments": {
    key: string,
    value: any,
    indexes: {locationGuid: string };
  }

}

type ObjectStoreNames = StoreNames<MyDB>;

const DB_VERSION = 6;

class DataManager {
  _db?: Promise<IDBPDatabase<MyDB>> = undefined;

  private async getDb() {
    if (!this._db) {
      throw new Error("_db unavailable");
    }
    return await this._db;
  }

  constructor() {
    this.initializeDatabase();
  }

  async onDatabaseInitialized() {
    const self = this;
    const deleteOldAndUploaded = async function(objectStoreName: ObjectStoreNames) {
      const savedItems: any[] = (await self.getItems(objectStoreName)) as any[];
      if (savedItems){
          const oldAndUploaded = savedItems.filter((item: any) => {
        const time = item.time ?? item.readingDate;
        const age = (Date.now() - time) / 1000 / 60 / 60 / 24; //in days
        return age >= 30 && item.uploaded;
      });

      await self.deleteItems(oldAndUploaded, objectStoreName);
      }
    
    };

    await deleteOldAndUploaded("saved-crib-scores");
   
  }

  initializeDatabase() {
    //console.log("data-manager initializeDatabase");
    this._db = openDB<MyDB>("feeder-app-DB", DB_VERSION, {
      upgrade(db, oldVersion, newVersion, transaction) {
        console.log(`oldVersion: ${oldVersion}`);
        console.log(`newVersion: ${newVersion}`);
  
        if (oldVersion < 1) {
          db.createObjectStore("todays-crib-scores", { keyPath: "guid" });
          db.createObjectStore("crib-scores-history", { keyPath: "guid" });
        }
        if (oldVersion < 2) {
          db.createObjectStore("saved-crib-scores", { keyPath: "guid" });
        }
        if (oldVersion < 3) {
          transaction.objectStore("saved-crib-scores").createIndex("locationGuid", "locationGuid");
          transaction.objectStore("todays-crib-scores").createIndex("locationGuid", "locationGuid");
          transaction.objectStore("crib-scores-history").createIndex("locationGuid", "locationGuid");
        }
        if (oldVersion < 4) { 
          db.createObjectStore("your-notifications", { keyPath: "guid" });
          transaction.objectStore("your-notifications").createIndex("locationGuid", "locationGuid");
        }
        if (oldVersion < 5){
          db.createObjectStore("ration-dmfs", { keyPath: "guid" });
          transaction.objectStore("ration-dmfs").createIndex("locationGuid", "locationGuid");
        }
        if (oldVersion < 6){
          db.createObjectStore("crib-reading-adjustments", { keyPath: "guid" });
          transaction.objectStore("crib-reading-adjustments").createIndex("locationGuid", "locationGuid");
        }
      }
    });
    return this._db.then(db => {
      this.onDatabaseInitialized();
    });
  }

  async addItems(objectStoreName: ObjectStoreNames, items: any[], progressCb?: (loaded: number, total: number) => void) {
    
    //skipping try/catch here so that outer calling methods can catch exceptions thrown
    const locationGuid = (store.state as any).user.location?.guid;
    if (!locationGuid) throw Error("dataManager.addItems error: No location guid found!");
    for (let item of items) item.locationGuid = locationGuid;
    const db = await this.getDb();
    const tx = db.transaction(objectStoreName, "readwrite");
    const os = tx.store;
    for (let i = 0; i < items.length; ++i) {
      //await os.add(items[i]);
      await os.put(items[i]);
      if (progressCb) progressCb(i + 1, items.length);
    }
    return tx.done;
  }

  clearCribScoreDownloadedData() {
    //console.log("clear database");
    if (!this._db) {
      throw new Error("_db unavailable");
    }
    return this._db.then(async function(db) {
      const clearPromises: Promise<any>[] = [];
      return await db.clear("todays-crib-scores");
    });
  }

  
  clearCribScoreHistoryData() {
    //console.log("clear database");
    if (!this._db) {
      throw new Error("_db unavailable");
    }
    return this._db.then(async function(db) {
      const clearPromises: Promise<any>[] = [];
      return await db.clear("crib-scores-history");
    });
  }

  async getObjectStoreCount(objectStoreName: ObjectStoreNames): Promise<number> {
    const db = await this.getDb();
    const locationGuid: any = (store.state as any).user.location?.guid;
    if (!locationGuid) throw Error("dataManager.getItems error: No location guid found!");
    return await db.countFromIndex(objectStoreName, "locationGuid", locationGuid);
  }

  async getItems(objectStoreName: ObjectStoreNames) {
    const locationGuid: any = (store.state as any).user.location?.guid;
    if (!locationGuid)throw Error("dataManager.getItems error: No location guid found!");
    const db = await this.getDb();
    const items = await db
      .transaction(objectStoreName)
      .store.index("locationGuid") //TODO: only get items that match user's selected locationGuid
      .getAll(locationGuid);
    //TODO: delete locationGuid (not from db) from items before returning them
    for (let item of items) delete item.locationGuid;
    return items;
  }

  async getItem(objectStoreName: ObjectStoreNames, key: string) {
    const locationGuid: any = (store.state as any).user.location?.guid;
    if (!locationGuid) throw Error("dataManager.getItem error: No location guid found!");
    const db = await this.getDb();
    let item = await db.transaction(objectStoreName).store.get(key);
    if (item?.locationGuid !== locationGuid) {
      console.error("datamanager.getItem blocked because locationGuid mismatch detected!");
      return undefined;
    }
    //TODO: delete locationGuid (not from db) from items before returning them
    if (item) delete item.locationGuid;
    return item;
  }

  async getSavedCribScore(guid: string) {
    return await this.getItem("saved-crib-scores", guid);
  }

  setCallIncrement(increment: number){
    localStorage.callIncrement = increment;
  }

  getCallIncrement(){
    if (localStorage.callIncrement){
      return localStorage.callIncrement
    }
    else {
      localStorage.callIncrement = 1.0;
      return 1.0;
    }
  }

  async deleteItems(items: any[], objectStoreName: ObjectStoreNames) {
    const db = await this.getDb();
    const os = db.transaction(objectStoreName, "readwrite").store;
    for (let i = 0; i < items.length; ++i) {
      await os.delete(items[i].guid);
    }
  }

  //Look for any items in any objectstore that have no locationGuid set, and update them with user's current locationGuid
  async updateAllHomelessItems(locationGuid: string) {
    const db = await this.getDb();
    for (const objectStoreName of db.objectStoreNames) {
      //console.log(objectStoreName);
      const tx = db.transaction(objectStoreName, "readwrite");
      for await (const cursor of tx.store) {
        //console.log(cursor.value);
        let o = cursor.value;
        if (!o.locationGuid) {
          o.locationGuid = locationGuid;
          await tx.store.put(o);
        }
        cursor.continue();
      }
    }
  }
}

export { DataManager, ObjectStoreNames };
