

































































































import { Component, Vue } from "vue-property-decorator";
import {
  DimssaButton,
  ButtonState
} from "@/components/shared/dimssa-button.vue";
import { addRtcReadyCallback } from "../main";
import Setup from "@/views/Setup.vue";

@Component({
  components: {
    DimssaButton
  }
})
export default class Home extends Setup {

  configuration = {};
  async mounted(){
   //  this.$store.dispatch("user/addFirebaseCallback", this.getData);
       addRtcReadyCallback(() => this.getData());
  }
 
  async getData(){  
    await this.kraals(false);
    await this.tractors(false);
    await this.trailers(false);
    await this.operators(false);
    await this.loaders(false);
    try {
      console.log("getData()");

      let gql = `query($guid:String!){
        Configuration(guid:$guid)
      }`;
      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: { guid: this.$store.state.user.location.guid },
        url: "https://pi.gigalot.systems:7777/feeder",
      });
      console.log("Done Downloading");
      this.configuration = JSON.parse(json.data.Configuration);
    } catch (err) {
      console.log("getData -> error: " + err);
    }
  }
    }
    

