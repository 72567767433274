var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.playbackDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.playbackDate=$event},"update:return-value":function($event){_vm.playbackDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Select Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.playbackDate),callback:function ($$v) {_vm.playbackDate=$$v},expression:"playbackDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.playbackDate),callback:function ($$v) {_vm.playbackDate=$$v},expression:"playbackDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.getReplayTracking(_vm.playbackDate);_vm.$refs.menu.save(_vm.playbackDate)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"600px"},attrs:{"center":_vm.gpsCenter,"zoom":17,"mapId":"4796866cc28f5c9b","map-type-id":"hybrid"}},_vm._l((_vm.gpsCoordinates),function(trac){return _c('GmapMarker',{key:trac.TractorID,attrs:{"position":_vm.getLatLong(trac.latitude,trac.longitude),"clickable":true,"draggable":true,"icon":_vm.icon,"animation:":"","google.maps.Animation.DROP,":""},on:{"click":function($event){_vm.center=_vm.m.position}}})}),1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tractorInfo,"dense":"","item-key":"tractorId","items-per-page":6},on:{"click:row":function($event){return _vm.setMapPoint($event)}},scopedSlots:_vm._u([{key:"item.TractorID",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getTractorName(item.TractorID)))]}},{key:"item.TrailerID",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getTrailerName(item.TrailerID)))]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }