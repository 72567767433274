
















import { Component, Vue } from "vue-property-decorator";
import * as Model from "@gigalot/data-models";
const lodash = require("lodash");

import {
  DimssaButton,
  ButtonState
} from "@/components/shared/dimssa-button.vue";

@Component({
  components: {
    DimssaButton
  }
})
export default class FeedDeliverySummary extends Vue {
  name = "feedDeliverySummary";
  feedDeliverySummary: any[] = [];
  loading = false;
  scheduleItems : any = [];
  mixingScheduledItems: any = [];
  mixResults: any = [];

  headers = [
    {
      text: "Ration",
      value: "key",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Total (kg)",
      value: "feedAllocated",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
      {
      text: "Loading Completed (kg)",
      value: "actualLoaded",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Feeding Completed (kg)",
      value: "actualFed",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Scheduled Loads",
      value: "totalLoads",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Completed Loads",
      value: "completedLoads",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    }
  ]; 

 feedSchedules = [];
  urlws = "wss://pi.gigalot.systems:7778/api/ws";
  options = {};

  variables = {};
  operationName = null;

  mounted() {
    this.$store.dispatch("user/addFirebaseCallback", this.getFeedSchedule);
   }


  async getFeedResults(item:any,value:boolean) {
    let kraalid = item.item.kraalId;
    let results: Model.FeedingResult[] = [];
    try {
      console.log("FeedResults( " + kraalid + " )");
      let query = {
        gql: `query FeedResult($guid:String!, $kraalId: String!){
        FeedResult(guid: $guid, kraalId: $kraalId){
          timestamp
          latitude
          longitude
          amountFed
          kraalID
          operatorID
          tractorID
          trailerID
          rationFed
        }
      }`,
        variables: {
          guid: this.$store.state.user.location.guid,
          kraalId: kraalid
        }
      };
      let json = await this.$store.dispatch("graphQl", query);
      console.log(JSON.stringify(json.data.FeedResult));
    } catch (err) {
      console.log("getFeedResult -> error: " + err);
    }
 }

  async getFeedSchedule() {
    try {
      console.log("FeedSchedule()");
      this.loading = true;
      let gql = `query($guid:String!){
        FeedSchedule(guid:$guid){
       typename
          guid
          feedingScheduleItems{
            typename
            guid
            kraalId
            ration
            feedAllocated
            actualFed
            sequence
            activeFeed
            feedAllocations{
              typename
              feedAllocated
              actualFed
              active
              allocated
            }
          }
        }
   
    MixingSchedule(guid:$guid){
          typename
          guid
          MixingScheduleItems{
            typename
            guid
            mixerCapacity
            ration
            rationGuid
            loadType
          }
        }

        MixResults(guid:$guid){
           guid
           MixID
           RationCode
           FeedDate
           MixStartTime
           MixEndTime
           RationWeightRequired
           MixStatus
           ActualWeight
           TractorID
           CallManagerID
           CallManagerAction
           LoadDuration
        }
      }`;

      let json = await this.$store.dispatch("graphQl", { gql, variables:{guid:this.$store.state.user.location.guid } });
     this.feedSchedules = json.data.FeedSchedule.feedingScheduleItems;
    this.mixingScheduledItems = json.data.MixingSchedule.MixingScheduleItems;
    this.mixResults = json.data.MixResults;
    this.scheduleItems = lodash.groupBy(this.feedSchedules, (si: any) => si.ration);

let t: any = []

for (let ration in this.scheduleItems) {
  t[ration] = this.scheduleItems[ration].reduce((acc:any, cur:any) => { 
    return {
      actualFed: acc.actualFed + cur.actualFed,
      feedAllocated: acc.feedAllocated + cur.feedAllocated,
      totalLoads: this.mixingScheduledItems.filter((item:Model.MixingScheduleItem)=>{ if ((item.loadType !== "Dump") && (item.ration === ration)) return item }).length?? 0, 
      completedLoads: this.mixResults.filter((objecto: Model.MixResult)=>{if (objecto.RationCode === ration)return objecto;}).length ?? 0,
      actualLoaded: this.mixResults.filter((objecto: Model.MixResult)=>{if (objecto.RationCode === ration)return objecto;}).reduce((counter:number, o: Model.MixResult)=>
          counter + o.ActualWeight,0).toFixed(0)?? 0
    }

  })
}

    var result: any[] = Object.keys(t).map(function (key) { 
        return {key, ...t[key]}; //unpacking it into an array because we need an array
    }); 

  this.feedDeliverySummary = result;
    } catch (err) {
      console.log("getFeedSchedule -> error: " + err);
    }
    this.loading = false;
  }

}
