var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.rationPlan.name),callback:function ($$v) {_vm.$set(_vm.rationPlan, "name", $$v)},expression:"rationPlan.name"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Ration Plan")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.addRation()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeRation()}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.moveRationUp()}}},[_c('v-icon',[_vm._v("mdi-arrow-up")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.moveRationDown()}}},[_c('v-icon',[_vm._v("mdi-arrow-down")])],1)],1),_c('v-card-text',[_c('v-data-table',{key:_vm.tableKey,staticClass:"elevation-1",attrs:{"headers":_vm.headersRationPlan,"items":_vm.rationPlan.rationItems,"item-key":"guid","sort-by":"order","items-per-page":100,"loading":_vm.loading,"Loading-text":"Loading... Please wait","show-select":"","single-select":""},scopedSlots:_vm._u([{key:"item.ration",fn:function(ref){
var item = ref.item;
return [_c('v-combobox',{attrs:{"chips":true,"items":_vm.rations,"item-text":"name","label":"Ration"},on:{"input":function($event){_vm.changed = true}},model:{value:(item.ration),callback:function ($$v) {_vm.$set(item, "ration", $$v)},expression:"item.ration"}})]}},{key:"item.defaultDays",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.defaultDays,"large":""},on:{"update:returnValue":function($event){return _vm.$set(item, "defaultDays", $event)},"update:return-value":function($event){return _vm.$set(item, "defaultDays", $event)},"save":_vm.close,"cancel":_vm.cancel,"close":_vm.close,"input":function($event){_vm.changed = true}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Days","type":"number","single-line":"","autofocus":""},on:{"input":function($event){_vm.changed = true}},model:{value:(item.defaultDays),callback:function ($$v) {_vm.$set(item, "defaultDays", _vm._n($$v))},expression:"item.defaultDays"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.defaultDays)+" ")])]}},{key:"item.variable",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){_vm.changed = true}},model:{value:(item.variable),callback:function ($$v) {_vm.$set(item, "variable", $$v)},expression:"item.variable"}})]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.priority,"large":""},on:{"update:returnValue":function($event){return _vm.$set(item, "priority", $event)},"update:return-value":function($event){return _vm.$set(item, "priority", $event)},"save":_vm.close,"cancel":_vm.cancel,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Priority","type":"number","single-line":"","autofocus":""},on:{"input":function($event){_vm.changed = true}},model:{value:(item.priority),callback:function ($$v) {_vm.$set(item, "priority", _vm._n($$v))},expression:"item.priority"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.priority)+" ")])]}},{key:"item.colour",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.colour,"large":""},on:{"update:returnValue":function($event){return _vm.$set(item, "colour", $event)},"update:return-value":function($event){return _vm.$set(item, "colour", $event)},"save":_vm.close,"cancel":_vm.cancel,"close":_vm.close,"input":function($event){_vm.changed = true}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":"","autofocus":""},model:{value:(item.colour),callback:function ($$v) {_vm.$set(item, "colour", $$v)},expression:"item.colour"}})]},proxy:true}],null,true)},[_c('v-chip',{attrs:{"color":item.colour.toLowerCase(),"dark":""}},[_vm._v(_vm._s(item.colour))])],1)]}}]),model:{value:(_vm.selectedRation),callback:function ($$v) {_vm.selectedRation=$$v},expression:"selectedRation"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('dimssa-button',{staticClass:"button",attrs:{"buttonState":"ready"},on:{"onclick":function($event){return _vm.cancel()}}},[_vm._v("Cancel")])],1),_c('v-col',[_c('dimssa-button',{staticClass:"button",attrs:{"buttonState":"ready"},on:{"onclick":function($event){return _vm.save(_vm.rationPlan)}}},[_vm._v("Save")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }