
































import { Component, Prop, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import * as DataModels from "@gigalot/data-models";
import { UploadableScanningResult, UploadableModel } from "@/models/uploadable";
import { ScanningResult } from "@gigalot/data-models";

@Component({
  components: {
    DimssaButton,
  },
})
export default class Outbox extends Vue {
  hideUploaded?: boolean = true;
  tab: any = null;
  cribScores: UploadableModel[] = [];

  get moment() {
    return this.$store.state.moment;
  }

  get refreshButtonState(): ButtonState {
    if (this.$store.state.upload.isBusyUploading) {
      return "busy";
    } else {
      return "ready";
    }
  }

  async mounted() {
    this.refresh();
  }

  sortOnTime(ascending: boolean) {
    return (a: any, b: any) => {
      let ret: number = 0;
      if (a.time < b.time) ret = -1;
      if (a.time === b.time) ret = 0;
      if (a.time > b.time) ret = 1;

      if (!ascending) ret *= -1;

      return ret;
    };
  }

  async refreshUi() {
    this.cribScores = await this.$store.dispatch("data/getCribScores");
    if (this.hideUploaded) {
      this.cribScores = this.cribScores.filter((cribScore) => !cribScore.uploaded);
    }
    this.cribScores.sort(this.sortOnTime(false));
  }

  async refresh() {
    //try {
    if (this.$store.state.upload.isBusyUploading) {
      this.refreshUi();
      return;
    }
    await this.refreshUi();
    await this.$store.dispatch("upload/upload");
    await this.refreshUi();
  }
}
