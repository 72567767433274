var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('FeedDeliverySummary')],1)],1),_c('v-row',[_c('v-col',[_c('BushDroppings')],1)],1),_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto",attrs:{"type":"table","tile":false,"loading":_vm.loading}}):_vm._e(),_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Deliveries")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(!_vm.loading)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.feedDeliveries,"item-key":"timestamp","items-per-page":50,"search":_vm.search,"show-group-by":""},scopedSlots:_vm._u([{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.moment(item.timestamp).format("hh:mm:ss")))]}},{key:"item.tractorID",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getTractorName(item.tractorID)))]}},{key:"item.trailerID",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getTrailerName(item.trailerID)))]}},{key:"item.operatorID",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getOperatorName(item.operatorID)))]}}],null,false,2393428796)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }